<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
     <div class="text-center">
      <h4 class="mt-5">
 Dedicated Course SEO
       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
        <b-row>
          
            <b-col md="6">
            <b-form-group
              label="Select Courses"
              label-for="blog-edit-course"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
              <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addCourseForm.course"
                label="name"
                :options="coursesList"
                :reduce="(val) => val"
                @input="getContent(addCourseForm.course)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
       
          <b-col md="6">
            <b-form-group
              label="Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name="Title"
                 
                >
              <b-form-input id="blog-edit-title" v-model="addCourseForm.title" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
          <b-col cols="6">
            <b-form-group
              label="Description"
              label-for="blog-content"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="Description"
                 
                >
                 <b-form-input
     
    v-model="addCourseForm.description"
    />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
           <b-col cols="6">
            <b-form-group
              label="Keywords"
              label-for="blog-content"
              class="mb-2"
            >
            <validation-provider
                  #default="{ errors }"
                  name="Keywords"
                 
                >
                <b-form-tags
                type="text"
                id="Keywords"
                v-model="addCourseForm.keywords"
              />
              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </validation-provider>
            </b-form-group>
          </b-col>
         
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
           
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
 
    <seo-deticated-list ref="seoList"/>

  </b-card>
  
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTags
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import SeoDeticatedList from "./SeoDeticatedList" 
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive } from "@vue/composition-api";
import store from '@/store'
import Vue from "vue";
import { emit } from "process";

export default {
  setup() {
 
    // const store = useStore();
    const refInputEl = ref(null);
      const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);
     const seoList=ref('')
    const Tabimage = ref("");
    const coursesList=ref([])
  
 store.dispatch('othercourses/AllothercoursesList')
        .then(response => {
          console.log("response",response.data.data)
          coursesList.value=response.data
        })
    const addCourseForm = reactive({
      other_course_id: "",
      title: "",
      description: "",
      keywords:"",
     
    });
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
     const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        refPreviewE2.value.src = base64;
      }
    );
     const addCourseFormvalidate=ref()
     const getContent = (item) => {
      if(item){
      let id=item.id 
      console.log(id)

      store.dispatch("othercourses/GetSeoCourseContent", { id}).then((response) => {
        console.log(response)
        // addCourseForm.related_courses=JSON.parse(response?.data.related_courses)
        addCourseForm.keywords = response?.data.data.keywords.split(',');
        addCourseForm.title = response?.data.data.title;
        addCourseForm.description = response?.data.data.description;
        
      });}
      else{
        addCourseForm.keywords=''
        addCourseForm.title =''
        addCourseForm.description=''

      }
    };
    const save = () => {
      
      console.log(addCourseForm)
      const formData = new FormData();

    
        
         addCourseFormvalidate.value.validate().then(success => {
        if (success) {
          formData.append("other_course_id", addCourseForm.course.id);
formData.append("title", addCourseForm.title);
formData.append("description", addCourseForm.description);
formData.append("keywords", addCourseForm.keywords);

           store.dispatch('othercourses/SeoOtherCourse',formData)
        .then(response => {
          addCourseForm.course=''
          addCourseForm.keywords = '';
        addCourseForm.title = '';
        addCourseForm.description = '';
          seoList.value.refetchData()
         
           Vue.swal({
                       title: "Seo Added ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
      console.log("alt_header_image", addCourseForm);
        }})
    };
    return {
      refInputEl,
      refPreviewEl,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      addCourseForm,
      inputImageRenderer,
      addCourseFormvalidate,
      coursesList,
      seoList,
     
      getContent,
      save,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
  
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    SeoDeticatedList,
    BFormTags
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mb-2.col-12 {
    margin: -1px !important;
  }
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }
  form.mt-2 {
    margin-bottom: 12px;
}
  
  </style>